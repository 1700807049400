export const environment = {
	production: false,
	recaptcha: {
		siteKey: '6LeoAFYfAAAAALncOm84wqeXcKl3KouuiFw4UFBH',
	},
	api: {
		url: 'https://testfront.comparandoseguro.com/cseguro-admin',
	},
  // emails: ['cgonzalez@ufconline.com']
	emails: ['cgonzalez@ufconline.com', 'jalberto@ufconline.com'],
};
