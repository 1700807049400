import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'primeng/dynamicdialog';
import { CustomContactComponent } from './pages/modal/custom-contact/custom-contact.component';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent {
	title = 'ufc-comparandoseguro-frontend';
	acceptCookies = true;
	constructor(
		public translate: TranslateService,
		private modalService: DialogService
	) {
		// Register translation languages
		translate.addLangs(['es']);
		// Set default language
		translate.setDefaultLang('es');
	}
	contactPopUp() {
		this.modalService.open(CustomContactComponent, {
			width: '100%',
		});
	}
}
