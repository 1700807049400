import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class LocalStorageService {
	constructor() {}

	getItem(name: string) {
	return	JSON.parse(localStorage.getItem(name) || '{}');
	}
	setItem(name: string, value: string) {
		localStorage.setItem(name, value);
	}
}
