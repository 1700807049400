<p-toast position="top-center"></p-toast>

<div class="container-fluid bg-white-100 page-min-height" #formContact>
	<div class="container-fluid">
		<div class="row align-items-center">
			<div class="col-12 col-lg-12 mx-auto bg-white-100 rounded-3">
				<div class="row align-items-center justify-content-center">
					<div class="col-4 col-lg-4 text-center d-none">
						<img class="img-fluid d-none" src="assets/images/ardilla/ardilla-contacto-8.png" />
					</div>
					<div class="col-12 col-lg-10 p-1">
						<h5 class="text-gray-300 fw-bold">
							{{
								contactData?.data
									? contactData?.data.text
									: '¡Gracias! por permitirnos ayudarte. En breve uno de nuestros agentes te contactará.'
							}}
						</h5>
						<form #f="ngForm" (ngSubmit)="sendInfo()" autocomplete="off">
							<div class="form-group">
								<label for="" class="form-label text-gray-300">Nombre completo <span class="text-danger fw-bold">*</span> </label
								><input type="text" class="form-control rounded-3" name="name" ngModel required #name="ngModel" />
								<div *ngIf="name.invalid && (name.dirty || name.touched)" class="alert">
									<span *ngIf="name.errors?.['required']"> El nombre es requerido. </span>
								</div>
							</div>
							<div class="form-group">
								<label for="" class="form-label text-gray-300">Correo electrónico <span class="text-danger fw-bold">*</span> </label
								><input type="email" class="form-control rounded-3" name="email" ngModel [email]="true" required #email="ngModel" />
								<div *ngIf="email.invalid && (email.dirty || email.touched)" class="alert">
									<span *ngIf="email.errors?.['required']"> El correo electrónico es requerido. </span>
									<span *ngIf="email.errors?.['email']"> Debe ser un correo electrónico valido. </span>
								</div>
							</div>
							<div class="row">
								<div class="col-12 col-md-6">
									<div class="form-group">
										<label for="" class="form-label text-gray-300">Teléfono celular <span class="text-danger fw-bold">*</span> </label
										><input
											type="text"
											class="form-control rounded-3"
											name="phone"
											min="10"
											placeholder="000 0000000"
											(keyup)="formatPhone($event)"
											ngModel
											mask="000 0000000"
											pattern="([0-9]{3})(\s?[0-9]{7})?"
											required
											#phone="ngModel"
										/>
										<div *ngIf="phone.invalid && (phone.dirty || phone.touched)" class="alert">
											<span *ngIf="phone.errors?.['required']"> El teléfono celular es requerido. </span>
											<span *ngIf="phone.errors?.['pattern']"> Formato de teléfono invalido. </span>
										</div>
									</div>
								</div>
								<div class="col-12 col-md-6">
									<div class="form-group">
										<label for="" class="form-label text-gray-300">Código postal <span class="text-danger fw-bold">*</span> </label
										><input
											type="text"
											class="form-control rounded-3"
											name="postal_code"
											ngModel
											placeholder="00000"
											pattern="(^[0-9]{5}$)"
											mask="00000"
											required
											#postal_code="ngModel"
										/>
										<div *ngIf="postal_code.invalid && (postal_code.dirty || postal_code.touched)" class="alert">
											<span *ngIf="postal_code.errors?.['required']"> El código postal es requerido. </span>
											<span *ngIf="postal_code.errors?.['pattern']"> Formato de código postal invalido. </span>
										</div>
									</div>
								</div>
							</div>

							<div class="row">
								<div class="col-12 text-gray-300">
									<span> Método preferido de contacto </span>
									<span class="text-danger fw-bold">*</span>
								</div>
								<div class="col-6">
									<div class="form-check">
										<input class="form-check-input" type="checkbox" value="" id="byEmail" name="byEmail" [ngModel]="checkAll" />
										<label class="form-check-label text-gray-300" for="byEmail"> Correo </label>
									</div>
									<div class="form-check">
										<input class="form-check-input" type="checkbox" value="" id="byCall" name="byCall" [ngModel]="checkAll" />
										<label class="form-check-label text-gray-300" for="byCall"> Llamada </label>
									</div>
								</div>
								<div class="col-6">
									<div class="form-check">
										<input class="form-check-input" type="checkbox" id="byWhatsapp" name="byWhatsapp" [ngModel]="checkAll" />
										<label class="form-check-label text-gray-300" for="byWhatsapp"> Whatsapp </label>
									</div>
									<div class="form-check">
										<input class="form-check-input" type="checkbox" value="" id="allMethods" (click)="checkAll = !checkAll" />
										<label class="form-check-label text-gray-300" for="allMethods"> Todos </label>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-12">
									<div class="form-check">
										<div class="row align-items-center">
											<div class="col-auto">
												<input class="form-check-input" type="checkbox" value="" id="terms" name="terms" ngModel required #terms="ngModel" />
											</div>
											<div class="col-10">
												<label style="font-size: 0.75rem" class="form-check-label text-gray-300" for="terms">
													Acepto los
													<a class="text-blue cursor" target="_blank" routerLink="/terms">términos y condiciones de uso</a>
													y he leído el
													<a class="text-blue cursor" target="_blank" routerLink="/privacy">aviso de privacidad</a>
													<span class="text-danger fw-bold px-2">*</span>
												</label>
											</div>
										</div>
										<div *ngIf="terms.invalid && (terms.dirty || terms.touched)" class="alert">
											<span *ngIf="terms.errors?.['required']"> El campo es requerido. </span>
										</div>
									</div>
								</div>
							</div>
							<div class="form-group">
								<re-captcha
									id="recaptcha"
									style="display: inline-block"
									name="recaptcha"
									#recaptcha="ngModel"
									[(ngModel)]="token"
									required
									[class.is-invalid]="recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)"
								></re-captcha>
								<div *ngIf="recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)" class="alert">
									<span *ngIf="recaptcha.errors?.['required']"> Este campo es requerido. </span>
								</div>
							</div>
							<div class="form-group">
								<button type="submit" class="button-primary bg-orange-100 border-0 px-4 mt-3 hover">
									{{ contactData?.data?.btnText || 'Enviar información' }}
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="container-fluid bg-gray-900 page-min-height" #doneMessage style="display: none">
	<div class="container">
		<div class="row align-items-center justify-content-center">
			<div class="col-12 col-lg-10 mx-auto rounded-3">
				<div class="row align-items-center">
					<div class="col-4 col-lg-4 text-center mx-auto">
						<img class="img-fluid text-center" src="assets/images/comparando/Logo_Comparando_2023-01.png" />
					</div>
					<div class="col-12 col-lg-8">
						<h3 class="text-gray-300 fw-bold">¡ Estas a un paso de tener tu plan ideal !</h3>
						<h6 class="text-gray-300">¡Gracias! por permitirnos ayudarte. En breve uno de nuestros agentes te contactará.</h6>
						<div class="form-group">
							<button (click)="goHome()" class="button-primary bg-orange-100 border-0 px-4 mt-3 hover">Volver al inicio ></button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
