import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';
import * as request from '../entity/plan-request';
import * as response from '../entity/plan-response';
import { environment } from '../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class RestApiService {
	// Define API
	apiURL = environment.api.url;
	//
	constructor(private http: HttpClient) {}

	// Http Options
	httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		}),
	};

	// HttpClient API post() method => Create employee
	getPlans(
		req: request.PlanRequestNamespace.PlanRequest
	): Observable<response.PlanResponseNamespace.PlanResponse[]> {
		return this.http
			.post<response.PlanResponseNamespace.PlanResponse[]>(
				this.apiURL + '/v2/api/plans',
				JSON.stringify(req),
				this.httpOptions
			)
			.pipe(
				retry(1),
				map((items) => this.mergeApiUrlAndLogo(items)),
				catchError(this.handleError)
			);
	}

	mergeApiUrlAndLogo(items: response.PlanResponseNamespace.PlanResponse[]) {
		return items.map((item) => ({
			...item,
			insurer_logo: this.apiURL + item.insurer_logo,
		}));
	}

	getBenefits(req: any): Observable<any> {
		return this.http
			.post<any>(
				this.apiURL + '/api/getBenefitsToCompare',
				JSON.stringify(req),
				this.httpOptions
			)
			.pipe(retry(1), catchError(this.handleError));
	}

	// Error handling
	handleError(error: any) {
		let errorMessage = '';
		if (error.error instanceof ErrorEvent) {
			// Get client-side error
			errorMessage = error.error.message;
		} else {
			// Get server-side error
			errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
		}
		console.error(errorMessage);
		return throwError(errorMessage);
	}
	sendFormContact(data: any) {
		return this.http.post(this.apiURL + '/api/sendmail', data);
	}

	async  getLatestMxRate() {
		return this.http.get(this.apiURL + '/v2/api/exchange/rate').toPromise()
	}
}
