import { Location } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import {
	DialogService,
	DynamicDialogConfig,
	DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { RestApiService } from 'src/app/services/restapi-service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-custom-contact',
	templateUrl: './custom-contact.component.html',
	styleUrls: ['./custom-contact.component.scss'],
})
export class CustomContactComponent implements OnInit {
	contactData!: any;
	@ViewChild('formContact') formContact!: ElementRef;
	@ViewChild('doneMessage') doneMessage!: ElementRef;

	@ViewChild('f') form!: NgForm;
	checkAll: boolean = false;
	token!: string | undefined;
	clientFormData: any;
	constructor(
		private location: Location,
		private router: Router,
		public ref: DynamicDialogRef,
		public config: DynamicDialogConfig,
		private messageService: MessageService,
		private planService: RestApiService,
		private _localStorageService: LocalStorageService
	) {}

	ngOnInit(): void {
		this.contactData = this.config || this.location.getState();
		this.clientFormData = this._localStorageService.getItem('clientFormData');
	}
	sendInfo() {
		if (this.form.invalid) {
			Object.keys(this.form.controls).forEach((key) => {
				this.form.controls[key].markAsDirty();
				this.form.controls[key].markAsTouched();
			});
			return this.messageService.add({
				severity: 'error',
				summary: 'Error',
				detail: 'Debes completar los campos requeridos',
			});
		}
		if (this.token === undefined) {
			return this.messageService.add({
				severity: 'error',
				summary: 'Error',
				detail: 'Debes completar el recaptcha',
			});
		}
		if (this.form.controls['terms'].invalid) {
			return this.messageService.add({
				severity: 'error',
				summary: 'Error',
				detail: 'Debes aceptar los terminos y condiciones',
			});
		}
		if (
			!this.form.controls['byCall'].value &&
			!this.form.controls['byWhatsapp'].value &&
			!this.form.controls['byEmail'].value
		) {
			return this.messageService.add({
				severity: 'error',
				summary: 'Error',
				detail: 'Debes seleccionar al menos un metodo de contacto',
			});
		}
		const contactForm = this.formContact.nativeElement as HTMLElement;
		const doneMessage = this.doneMessage.nativeElement as HTMLElement;
		// Send email
		delete this.form.value.recaptcha;
		this._localStorageService.setItem(
			'clientFormData',
			JSON.stringify(this.form.value)
		);
		// const data = {
		// 	to: environment.emails,
		// 	subject: 'Contact form',
		// 	data: {
		// 		...this.form.value,
		// 	},
		// 	from: 'Comparando Seguro Mexico <info@comparandoseguro.com>',
		// };
		// const dataClient = {
		// 	to: [this.form.value.email],
		// 	subject: 'Contact form',
		// 	data: {
		// 		...this.form.value,
		// 	},
		// 	from: 'Comparando Seguro Mexico <info@comparandoseguro.com>',
		// 	emailTemplate: 'comparandomx/contact-client',
		// };
		// this.planService.sendFormContact(data).subscribe({
		// 	next: (response) => console.log(response),
		// 	error: (err) => console.log(err),
		// });
		// this.planService.sendFormContact(dataClient).subscribe({
		// 	next: (response) => console.log(response),
		// 	error: (err) => console.log(err),
		// });

		if (this.contactData.data && this.contactData.data.item) {
			this.ref.destroy();
			this.ref.close(this.formContact);
			setTimeout(() => {
				this.ref.close(this.formContact);
				this.router.navigate([this.contactData.data.path], {
					state: { data: this.contactData.data.item },
				});
			}, 1000);
			return;
		}
		if (this.contactData.data) {
			this.ref.close(this.formContact);
			setTimeout(() => {
				this.ref.close(this.formContact);
				this.router.navigate([this.contactData.data.path]);
			}, 1000);
			return;
		}

		contactForm.style.display = 'none';
		doneMessage.style.display = 'flex';
	}
	redirectToBenefits() {
		this.router.navigate(['/benefits']);
	}
	formatPhone(event: any) {
		const phone = event.target.value;
		// if (phone.length === 10) {
		const phoneFormatted = phone.replace(/(^[0-9]{3})([0-9]{7}$)/g, '$1 $2');
		this.form.controls['phone'].setValue(phoneFormatted);
		// }
	}
	goTo(route: string) {
		this.ref.close(this.formContact);
		this.router.navigate([route]);
	}
	goHome() {
		this.ref.close();
		this.router.navigateByUrl('/');
	}
}
