import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { DialogService, DynamicDialogConfig, DynamicDialogModule } from 'primeng/dynamicdialog';
import { SliderModule } from 'primeng/slider';
import { TableModule } from 'primeng/table';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DropdownModule } from 'primeng/dropdown';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { GalleriaModule } from 'primeng/galleria';
import { TooltipModule } from 'primeng/tooltip';

const primengModules = [
	DialogModule,
	DynamicDialogModule,
	SliderModule,
	TableModule,
	ProgressSpinnerModule,
	DropdownModule,
	ToastModule,
	GalleriaModule,
	TooltipModule
];

@NgModule({
	imports: [CommonModule, ...primengModules],
	exports: [...primengModules],
	providers: [DialogService, DynamicDialogConfig, MessageService],
})
export class AngularPrimeModule {}
